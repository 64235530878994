<template>
    <div class="wrapper productSale">
        <table class="custom-table">
            <thead>
                <tr>
                    <td class="custom-th custom-th-bg" style="min-width:.5rem">
                        序号
                    </td>
                    <td class="custom-th custom-th-bg" style="min-width:1rem">
                        品种
                    </td>
                    <td class="custom-th custom-th-bg" style="min-width:1rem">
                        项目
                    </td>
                    <template v-for="(item, index) in tableData[0].salesReports">
                        <td class="custom-th custom-th-bg mongth-th" :key="'th'+index" v-if="item.date>0">
                            {{ item.date }}月
                        </td>
                        <td class="custom-th custom-th-bg mongth-th" :key="'th'+index" v-if="item.date===0">
                            总计
                        </td>
                    </template>
                </tr>
            </thead>
            <tbody>
                <template v-for="(item, index) in tableData">
                    <tr :key="index" :class="{'border6': index<tableData.length-1}">
                        <td class="custom-th" :class="{'custom-th-odd':index%2===0,'custom-th-even':index%2===1}" style="width:.6rem;">
                            {{ index+1 }}
                        </td>
                        <td class="custom-th">
                            {{ item.label }}
                        </td>
                        <td v-if="item.salesReports">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>销量(万方)</td>
                                    </tr>
                                    <tr>
                                        <td>售价(元/方)</td>
                                    </tr>
                                    <tr>
                                        <td>成本(元/方)</td>
                                    </tr>
                                    <tr>
                                        <td>毛利率(%)</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td v-if="item.salesMortarInfos">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>销量(万吨)</td>
                                    </tr>
                                    <tr>
                                        <td>售价(元/吨)</td>
                                    </tr>
                                    <tr>
                                        <td>成本(元/吨)</td>
                                    </tr>
                                    <tr>
                                        <td>毛利率(%)</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <template v-if="item.salesReports">
                            <td v-for="(k, i) in item.salesReports" :key="i">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td @click="showDataDrills(item,k,'sales','Concrete')" v-if="isDrill">
                                                <a href="javascript:;">
                                                    {{ k.sales }}
                                                </a>
                                            </td>
                                            <td v-else>
                                                {{ k.sales }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(item,k,'price','Concrete')" v-if="isDrill">
                                                <a href="javascript:;">
                                                    {{ k.price }}
                                                </a>
                                            </td>
                                            <td v-else>
                                                {{ k.price }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(item,k,'cost','Concrete')" v-if="isDrill">
                                                <a href="javascript:;">
                                                    {{ k.cost }}
                                                </a>
                                            </td>
                                            <td v-else>
                                                {{ k.cost }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(item,k,'margin_rate','Concrete')" v-if="isDrill">
                                                <a href="javascript:;">
                                                    {{ k.margin_rate }}
                                                </a>
                                            </td>
                                            <td v-else>
                                                {{ k.margin_rate }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </template>
                        <template v-if="item.salesMortarInfos">
                            <td v-for="(k, i) in item.salesMortarInfos" :key="i">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td @click="showDataDrills(item,k,'sales','Mortar')" v-if="isDrill">
                                                <a href="javascript:;">
                                                    {{ k.sales }}
                                                </a>
                                            </td>
                                            <td v-else>
                                                {{ k.sales }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(item,k,'price','Mortar')" v-if="isDrill">
                                                <a href="javascript:;">
                                                    {{ k.price }}
                                                </a>
                                            </td>
                                            <td v-else>
                                                {{ k.price }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(item,k,'cost','Mortar')" v-if="isDrill">
                                                <a href="javascript:;">
                                                    {{ k.cost }}
                                                </a>
                                            </td>
                                            <td v-else>
                                                {{ k.cost }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(item,k,'cost','Mortar')" v-if="isDrill">
                                                <a href="javascript:;">
                                                    {{ k.margin_rate }}
                                                </a>
                                            </td>
                                            <td v-else>
                                                {{ k.margin_rate }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </template>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    // 产销概况
    name: 'product-sale-table',
    components: {},
    props: {
        loadObj: {
            type: [Object],
        },
        reportData: {
            type: [Object],
        },
        queryStr: {
            type: [String],
        },
    },
    data() {
        return {
            // 产销概况table循环data
            tableData: [
                {
                    salesReports: [],
                },
            ],
            // 产销概况——混泥土数据
            ConcreteData: [],
            // 产销概况——砂浆数据
            MortarData: [],
            isDrill: true, // 是否钻取 
        };
    },
    watch: {
        queryStr: function () {
            this.gitConcreteData();
        },
    },
    computed: {},
    methods: {
        gitConcreteData() {
            this.$axios
                .get('/interfaceApi/report/sales/report/goncrete/' + this.queryStr)
                .then(res => {
                    this.ConcreteData = res;
                    // 获取产销概况——砂浆数据
                    this.gitMortarData();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        gitMortarData() {
            this.$axios
                .get('/interfaceApi/report/sales/report/mortar/' + this.queryStr)
                .then(res => {
                    this.MortarData = res;
                    // 合并砂浆和混泥土数据
                    this.tableData = this.ConcreteData.concat(this.MortarData);
                    // this.loadObj.close();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 数据钻取
        // eslint-disable-next-line max-params
        showDataDrills(data, rowData, field, type) {
            let parseField = 0;
            let parsetText = null;
            let showType = null;
            switch (field) {
                case 'sales':
                    parseField = 1;
                    parsetText = '销量(万方)';
                    break;
                case 'price':
                    parseField = 2;
                    parsetText = '售价(元/方)';
                    showType = 1;
                    break;
                case 'cost':
                    parseField = 3;
                    parsetText = '成本(元/方)';
                    showType = 1;
                    break;
                default:
                    parseField = 4;
                    parsetText = '毛利率(%)';
                    showType = 2;
            }
            let parseType = 0;
            if (type === 'Concrete') {
                if (data.label === '混凝土') {
                    parseType = 1;
                } else {
                    parseType = 2;
                }
            } else if (type === 'Mortar') {
                if (data.label === '砂浆') {
                    parseType = 3;
                } else {
                    parseType = 4;
                }
            }
            let istotal = 0;
            let month = 0;
            if (rowData.date === 0) {
                istotal = 1;
                if (this.reportData.month === 0) {
                    month = 12;
                } else {
                    month = this.reportData.month;
                }
            } else {
                istotal = 0;
                month = rowData.date;
            }
            const params = {
                field: parseField,
                text: parsetText,
                type: parseType,
                name: data.label,
                year: this.reportData.year,
                month: month,
                business_code: 'yyqk',
                istotal: istotal,
                treeid: this.reportData.treeid,
                firstPop: true,
                sourceReport: 'productSaleTable',
                showType: showType,
            };
            const pop = this.$toast({
                title: false,
                t_url: 'reportManage/operationTrends/summary/dataDrills/DrillDownPop',
                type: 'eject',
                width: '80%',
                extr: { closeBtn: { width: '.6rem', height: '.6rem', 'line-height': '.6rem' }, params: params },
            });
            window.window.$globalHub.$store.commit('setChartPop', pop);
        },
        isDrillFn() {
            if (this.reportData.org_type >= 5) {
                this.isDrill = false;
            } else {
                this.isDrill = true;
            }
        },
    },
    created() {
        // 获取产销概况——混泥土数据
        this.gitConcreteData();
        this.isDrillFn();
    },
    updated() {
        this.isDrillFn();
    },
    mounted() {},
};
</script>
<style lang="stylus">
.productSale
    table
        table
            width 100%
            border-collapse collapse
            tr
                &:last-child
                    td
                        border none !important
            td
                border none !important
                border-bottom 1px solid #ebeef5 !important
</style>